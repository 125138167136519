<template>
  <single-column-layout>
    <div class="home" v-if="project">
        <div class="home__description">
        <div class="home__description__content">
            <h1>About EmProps</h1>
            <article>
                <p>
                    Emergent Properties is a curated platform to create and collect 
                    Generative NFTs on the Secret Network blockchain, with built-in 
                    permissionless data privacy by default.
                </p>
                <p>
                    Our mission is to explore, advance, and champion creative projects 
                    at the intersection of art and code, while leveraging the unique 
                    privacy powers of Secret Network.
                </p>
                <p>
                    “The Big Bang” is our first Generative NFT collection, 
                    launching January 2nd, 2022.
                </p>
            </article>

            <!--Remove Temporal-->

            <!-- <button
                @click="$router.push({ name: 'home' })"
            >VIEW THE BIG BANG
            </button> -->
        </div>
        </div>

        <div class="home__image">
            <div class="loading_wrapper" v-if="loadingRandomImg">
                <loading 
                    position="center"
                /> 
            </div>
            <div v-else>
                <img
                    v-if="randomArt && project.mintedCount > 0" 
                    :src="randomArt.previewImageUrl" alt="Preview image"
                >
                <img v-else :src="project.previewImageUrl" alt="Preview Image">
            </div>
        </div>

    </div>
  </single-column-layout>
</template>

<script>
import DoubleColumnLayout from "../layouts/DoubleColumnLayout.vue";
import api from '@/service/api';
import SingleColumnLayout from '../layouts/SingleColumnLayout.vue';
import Loading from '@/components/Loading';

export default {
    name: 'about-emprops',
    components: { DoubleColumnLayout, SingleColumnLayout, Loading },
    data() {
      return {
        project: null,
        randomArt: null,
        loadingRandomImg: false,
      }
    },
    async mounted() {
      window.scrollTo({ top: 0 });
      await this.getProject();
      await this.getProjectImage();
    },
    methods: {
      async getProject() {
        const projects = await api.projects.getAll();
        this.project = projects[0];
      },
      async getProjectImage() {
        this.loadingRandomImg = true;
        try {
            this.randomArt = await api.projects.arts.getRandomImage(this.project.id);
        } catch (error) {
            console.error(error)
        }finally {
            this.loadingRandomImg = false;
        }
      },
    },
}
</script>

<style lang="scss" scoped>
.home {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 48px;
    padding: 26px 0px;
	&__description {
        margin-top: 195px;
        padding: 0px 16px 0px 0px;
		&__content {
			h1 {
                line-height: 46px;
				margin: 0px;
				font-style: italic;
                margin-bottom: 16px;
			} 
            p {
                margin-bottom: 24px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
            button {
                width: 171px;
                padding: 11px 20px;
                line-height: 24px;
                margin: 26px 0px 0px 0px;
                font-weight: 700;
                cursor: pointer;
                border-radius: 8px;
            }
		}
	}
	&__image {
        padding: 16px 0px 16px 16px;
		img {
			width: 100%;
            height: auto;
		}
	}
    @include respond-to("medium and down") {  
		column-gap: 24px;
        padding: 0px;
		&__description {
            margin-top: 16px;
		}
	}

	@include respond-to("small and down") {  
		grid-template-columns: unset;
		row-gap: 16px;
        padding: 0px;
		&__description {
            margin-top: 0px;
			order: 2;
            padding: 0px;
		}
		&__image {
			order: 1;
            padding: 0px;
		}
	}
}

</style>
